<script setup lang="ts">
import { ListingMostSoldProps } from "./ListingMostSold.props"
import { truncateString } from "~/utils/truncateString"
import { formatMoney } from "~/utils/formatters"
import { PRODUCT_CLICKED } from "~/config/algolia-insights"
import {
  algoliaEventClickProductAfterSearch,
  algoliaEventClickProduct
} from "~/utils/algolia"
import { algoliaSync } from "~/config/algolia"
import { fallbackImage } from "~/utils/constants"
import { isGreaterThan } from "~/utils/productPrice"
import { VerticalProps } from "./Vertical.props"

const props = defineProps<ListingMostSoldProps>()

const truncateTitle = computed(() => truncateString(props.title, 25))

const clickProductEvent = () => {
  const algolia = algoliaSync()
  localStorage.setItem(
    "algolia-last-clicked-product",
    `{"queryID": "${props?.queryID ?? ""}","path": "${props?.path ?? ""}"}`
  )
  if (props.queryID) {
    algoliaEventClickProductAfterSearch(
      algolia.indices.products,
      `${PRODUCT_CLICKED}`,
      [`product_${props.productCode}`],
      props.queryID,
      [props.position ?? 1]
    )
  } else {
    algoliaEventClickProduct(algolia.indices.products, `${PRODUCT_CLICKED}`, [
      `product_${props.productCode}`
    ])
  }
}

const isRatingVisible = computed(
  () => props.rating && props.rating > 0 && props.reviews && props.reviews > 0
)
</script>

<template>
  <div
    class="
      relative
      block
      h-full
      w-[184px]
      transform-gpu
      cursor-pointer
      overflow-hidden
      rounded-lg
      bg-white
      drop-shadow-01
      hover:drop-shadow-02
      md:w-auto
    "
  >
    <GAItem :id="props.productCode" :item="[props as VerticalProps]">
      <GASelectItem class="flex h-full flex-col">
        <div>
          <div class="relative">
            <div
              v-for="promo in promotions"
              class="
                absolute
                left-0
                top-0
                z-40
                mb-2
                flex flex-wrap
                gap-2
                px-4
                py-2
              "
            >
              <BadgeProduct
                v-if="promo.theme == 'SCONTO'"
                :key="`promo-${promo.theme}`"
                v-bind="promo"
              />
            </div>
          </div>
          <div>
            <AppLink :to="path" @click="clickProductEvent">
              <UtilsWrapperImage
                v-if="productImage"
                v-bind="productImage"
                class="wrapper-image-fit-contain wrapper-image-fixed-132"
                sizes="200px md:300px lg:300px"
              />
              <UtilsWrapperImage
                v-else
                v-bind="fallbackImage"
                class="wrapper-image-fit-contain wrapper-image-fixed-132"
              />
            </AppLink>
          </div>
        </div>
        <div class="flex grow flex-col justify-between px-4 py-2">
          <div class="block text-start">
            <p class="title beaver-bold mb-2">
              <AppLink :to="path" @click="clickProductEvent">
                {{ truncateTitle }}
              </AppLink>
            </p>
          </div>
          <div>
            <div
              v-if="isRatingVisible"
              class="rating-container mb-2 flex items-center gap-1"
            >
              <UtilsStars :stars="rating" />
              <span class="mouse"> {{ reviews }}</span>
            </div>
            <div class="price-container">
              <div class="flex flex-wrap items-baseline">
                <span
                  v-if="currentPrice"
                  class="discount-price hyppo-bold text-black-800"
                >
                  {{ formatMoney(currentPrice) }}
                </span>
                <span
                  v-if="isGreaterThan(oldPrice, currentPrice)"
                  class="full-price beaver ml-2 text-black-80 line-through"
                >
                  {{ formatMoney(oldPrice) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </GASelectItem>
    </GAItem>
  </div>
</template>
